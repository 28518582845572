import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'jp']

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
  ) {
    let browserLang
    this.translate.addLangs(this.languages)
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang')
    } else {
      browserLang = translate.getBrowserLang()
    }
    translate.use(browserLang.match(/en|jp/) ? browserLang : 'en')
  }

  public setLanguage(lang) {
    this.translate.use(lang)
    this.cookieService.set('lang', lang)
  }


  getEnJSON() {
    return {
      "GLOBAL": {
        "ANY": "Any",
        "ALL": "All",
        "EMAIL": "Email"
      },
      "HEADER": {
        "SEARCH": "Search...",
        "NOTIFICATIONS": {
          "TITLE": "Notifications",
          "VIEW_ALL": "View All",
          "LOAD_MORE": "View More.."
        },
        "SETTINGS": "Settings",
        "PROFILE": "Profile",
        "LOGOUT": "Logout"
      },
      "MENUITEMS": {
        "MENU": "Menu",
        "DASHBOARDS": "Dashboard",
        "CANDIDATES": "Candidates",
        "COMPANIES": "Companies",
        "DEMAND_LIST": "Demand List",
        "SCHOOL_LIST": "School List",
        "DEMAND_AVAILABLE": "Demand Available",
        "STUDENTS": "Students",
        "USERS": "Users",
        "INTERVIEW": "Interview",
        "CALENDAR": "Calendar",
        "INBOX": "Inbox",
        "FILEMANAGER": "File Manager",
        "INTERVIEW_LIST": "Interview List",
        "JAPANESE_SCHOOLS": "Japanese Schools"
      },
      "BUTTONS": {
        "INVITE": "Invite User",
        "CANCEL": "Cancel",
        "EDIT": "Edit",
        "EDIT_PROFILE": "Edit Profile",
        "UPDATE": "Update",
        "ADD_DEMAND": "Add New Demand",
        "CLEAR": "Clear",
        "SEARCH": "Search",
        "SUBMIT": "Submit",
        "RESET": "Reset",
        "APPLY_IN_THIS_COMPANY": "Apply in this Company",
        "GO_BACK": "Go Back",
        "CONTINUE": "Continue",
        "SAVE_AND_CONTINUE": "Save and Continue",
        "PREV": "Prev",
        "NEXT": "Next",
        "ADD_CANDIDATE": "Add Candidate",
        "SUBMIT_PROFILE": "Submit Profile",
        "ADD_MORE_FAMILY_MEMBER": "Add More Family Member"
      },
      "TABLE": {
        "NAME": "Name",
        "STATUS": "Status",
        "ACTIONS": "Actions"
      },
      "AUTHENTICATION": {
        "TEXT": "Authentication",
        "BADGE": "New",
        "LIST": {
          "LOGIN": "Login",
          "FORGOT_PASS": "Forgot your password?",
          "RESET_PASS": "Reset Password",
          "RESET_PASS_MSG": "Enter Email associated with Global Works Account",
          "GREETINGS_1": " Welcome Back !",
          "GREETINGS_2": " Sign in to continue to Global Works",
          "REGISTER": "Register",
          "RECOVERPWD": "Recover Password",
          "REMEMBER_IT": "Remember It?",
          "SIGN_IN_HERE": "Sign In Here",
          "CONFIRMMAIL": "Confirm Mail",
          "EMAILVERIFICATION": "Email verification"
        }
      },
      "FORMS": {
        "NAME": "Name",
        "GENDER": "Gender",
        "DATE_OF_BIRTH": "Date of Birth",
        "CATEGORY": "Category",
        "EMAIL": "Email",
        "EMAIL_PLACEHOLDER": "Enter Email Address",
        "NATIONALITY": "Nationality",
        "PHONE_NUMBER": "Phone Number",
        "ADDRESS": "Address",
        "CITY": "City",
        "STATE": "State / Province",
        "COUNTRY": "Country",
        "PROFESSION": "Profession",
        "CURRENT_PROFESSION": "Current Profession",
        "JAPANESE_LANG_CERTIFICATION": "Japanese Language Certification",
        "SKILL_EVAL_CERTIFICATION": "Skill Evaluation Certification",
        "TYPE": "Type",
        "FILES": "Files",
        "STATUS": "Status",
        "ACTIONS": "Actions",
        "UPLOADED": "Uploaded",
        "UPLOADING": "Uploading",
        "WAITING": "Waiting",
        "LANGUAGE_SPOKEN": "Language Spoken",
        "YES": "Yes",
        "NO": "No",
        "DOC_NOT_AVAILABLE": "Document not available",
        "DATA_NOT_AVAILABLE": "Data not available",
        "DEGREE": "Academic Degree",
        "COMPLETION_DATE": "Completion Date",
        "DETAILS": "Details",
        "STARTED_ON": "Started on",
        "WORKED_TILL": "Worked till",
        "SSW_CATEGORY": "SSW Category",
        "FIRST_NAME": "First Name",
        "MIDDLE_NAME": "Middle Name",
        "LAST_NAME": "Last Name",
        "PROFILE_PHOTO": "Profile Photo",
        "QUALIFICATIONS": "Qualifications",
        "ADD_QUALIFICATIONS": "Add Qualifications",
        "WORK_EXPERIENCE": "Work Experience",
        "ADD_WORK_EXPERIENCE": "Add Work Experience",
        "SCORE_IN_PERCENT": "Score in Percentage",
        "SCORE": "Score",
        "DOCUMENTS": "Documents",
        "UPLOADS": "Uploads",
        "DOCUMENT_UPLOADS": "Document Uploads",
        "DOCUMENT_UPLOADS_NOTE": "Upload the below specified documents here.",
        "DOCUMENTS_NOT_UPLOADED": "Document not uploaded yet",
        "PLEASE_WAIT": "Please Wait..",
        "NO_PROFILE_MESSAGE": "Looks like you do not have a profile. Click the button below to start building your profile.",
        "START_PROFILE_MESSAGE": "Click here to start your profile",
        "SALARY": "Salary",
        "POSITION": "Position",
        "LOCATION": "Location",
        "DEADLINE": "Deadline",
        "DEMAND": "Demand",
        "COMPANY_NAME": "Company Name",
        "HIRING_COMPANY_NAME": "Hiring Company Name",
        "HIRING_COMPANY": "Hiring Company",
        "COMPANY_EMAIL": "Company Email",
        "COMPANY_ADDRESS": "Company Address",
        "DEMAND_CATEGORY": "Demand Category",
        "NUMBER_OF_DEMAND": "Number of Demand",
        "EXPECTED_SALARY": "Expected Salary",
        "ALLOWANCE": "Allowance",
        "REQUIREMENT_PDF": "Requirement PDF",
        "SEARCH": "Search",
        "CLEAR": "Clear",
        "VIEW_DETAILS": "View Details",
        "AGE": "Age",
        "CONTACT": "Contact",
        "TOTAL_DEMAND": "Total Demand",
        "INTERVIEW_STATUS": "Interview Status",
        "COMPANY_INFORMATIONS": "Company Informations",
        "SCHOOL_INFORMATIONS": "School Informations",
        "CREATED_ON": "Created On",
        "USERS_NOT_FOUND": "Users Not Found",
        "USERS_TABLE": "Users Table",
        "INVITE_USER": "Invite User",
        "COMPANIES": "Companies",
        "SCHOOLS": "Schools",
        "LANGUAGE_SCHOOLS": "Language & Training School",
        "WORKING_CANDIDATE": "Working Candidate",
        "PASSWORD": "Password",
        "USER_TYPE": "User Type",
        "USER_TYPE_PLACEHOLDER": "Select User Type",
        "USER_APPLICATION_TYPE": "Candidate Application Type",
        "USER_APPLICATION_TYPE_PLACEHOLDER": "Select Candidate Application Type",
        "SCHOOL_TYPE": "School Type",
        "SCHOOL_TYPE_PLACEHOLDER": "Select type of school.",
        "TYPE_REQUIRED": "User type is required.",
        "FULL_NAME": "Full Name",
        "FULL_NAME_PLACEHOLDER": "Enter your full Name",
        "ZIPCODE": "ZIP Code",
        "SPONSOR_TITLE": "Sponsor's Title",
        "SPONSOR_FIRST_NAME": "Sponsor's First Name",
        "SPONSOR_LAST_NAME": "Sponsor's Last Name",
        "RELATIONSHIP_TO_YOU": "Relationship To You",
        "SPONSOR_LIVING_WITH_YOU": "Is your sponsor living with you?",
        "SPONSOR_EMAIL": "Sponsor's Email",
        "SPONSOR_PHONE_NUMBER": "Sponsor's Phone Number",
        "EMPLOYER": "Employer",
        "ANNUAL_INCOME_AMOUNT": "Annual Income Amount(USD)",
        "SPONSOR_PROFESSION_NOTE": "Former occupation if retired or currently not employed.",
        "SPONSOR_EMPLOYER_NOTE": "If retired or currently not employed, answer with 'N/A'",
        "SPONSOR_ANNUAL_INCOME_NOTE": "These answers will not affect nor factor into your evaluation",
        "AWAITING_RESULT": "Awaiting Result",
        "CURRENTLY_ENROLLED_IN_SCHOOL": "Are you currently enrolled in this school",
        "CURRENTLY_ENROLLED": "Currently Enrolled",
        "DEGREE_ONLY": "Degree",
        "FULL_NAME_PLACEHOLDER_2": "Enter full Name",
        "ADD_FOR_WORKING_VISA": "Add for Working Visa",
        "ADD_FOR_STUDENT_VISA": "Add for Student Visa",
        "UPDATE": "Update",
        "RELATION": "Relation",
        "RELATION_DETAIL": "Relation Detail",
        "LOGO": "Logo",
        "WEBSITE": "Website",
        "SELECT_SSW_CATEGORY_PLACEHOLDER": "Select SSW Category",
        "FIRST_NAME_PLACEHOLDER": "Enter First Name",
        "MIDDLE_NAME_PLACEHOLDER": "Enter Middle Name",
        "LAST_NAME_PLACEHOLDER": "Enter Last Name",
        "SELECT_GENDER_PLACEHOLDER": "Select Gender",
        "DOB_PLACEHOLDER": "Enter Date of Birth",
        "NATIONALITY_PLACEHOLDER": "Enter Nationality",
        "PRIOR_STAY_PLACEHOLDER": "Prior Japan Stay",
        "YEAR_PLACEHOLDER": "Year",
        "MONTH_PLACEHOLDER": "Month",
        "COUNTRY_CODE_PLACEHOLDER": "Country Code",
        "PHONE_PLACEHOLDER": "Enter Phone Number",
        "ADDRESS_PLACEHOLDER": "Enter Address",
        "CITY_PLACEHOLDER": "Enter City",
        "STATE_PLACEHOLDER": "Enter State",
        "ZIPCODE_PLACEHOLDER": "Enter ZIP Code",
        "COUNTRY_PLACEHOLDER": "Country",
        "CURRENT_PROFESSION_PLACEHOLDER": "Enter your current profession",
        "LANGUAGE_SPOKEN_PLACEHOLDER": "Select Language Spoken",
        "JP_LANG_CERTIFICATE_PLACEHOLDER": "Select Japanese Language Certification",
        "STATUS_PLACEHOLDER": "Status",
        "SCORE_PLACEHOLDER": "Score",
        "DEGREE_PLACEHOLDER": "Degree",
        "PASSED_YEAR_PLACEHOLDER": "Passed Year",
        "PASSED_MONTH_PLACEHOLDER": "Passed Month",
        "DETAILS_PLACEHOLDER": "Details",
        "STARTED_YEAR_PLACEHOLDER": "Started Year",
        "ENDED_YEAR_PLACEHOLDER": "Ended year",
        "TITLE_PLACEHOLDER": "Select Title",
        "SPONSER_FIRST_NAME_PLACEHOLDER": "Enter Sponsor First Name",
        "SPONSER_LAST_NAME_PLACEHOLDER": "Enter Sponsor Last Name",
        "SPONSER_RELATION_DETAIL_PLACEHOLDER": "Provide brief relation detail",
        "SPONSOR_RELATION_DETAIL": "Relation Detail",
        "RELATION_PLACEHOLDER": "Relation",
        "PROFESSION_PLACEHOLDER": "Enter Profession",
        "EMPLOYER_PLACEHOLDER": "Enter Employer",
        "ANNUAL_INCOME_PLACEHOLDER": "Enter Annual Income Amount",
        "COMPANY_NAME_PLACEHOLDER": "Enter Company Name",
        "WEBSITE_PLACEHOLDER": "Enter Website",
        "SCHOOL_NAME_PLACEHOLDER": "Enter School Name",
        "HIRING_COMPANY_NAME_PLACEHOLDER": "Enter Hiring Company Name",
        "COMPANY_EMAIL_PLACEHOLDER": "Enter Company Email",
        "COMPANY_PHONE_PLACEHOLDER": "Enter Company Phone Number",
        "COMPANY_ADDRESS_PLACEHOLDER": "Enter Company Address",
        "DEMAND_CATEGORY_PLACEHOLDER": "Select a Demand Category",
        "POSITION_TO_HIRE_PLACEHOLDER": "Enter Position to hire",
        "TOTAL_DEMAND_QUANTITY_PLACEHOLDER": "Enter Total Demand Quantity",
        "EXPECTED_SALARY_PLACEHOLDER": "Enter Expected Salary/Allowance",
        "DEMAND_DEADLINE_PLACEHOLDER": "Demand Deadline Date",
        "COUNTRY_FILTER_PLACEHOLDER": "Select Country to filter",
        "SSW_CATEGORY_FILTER_PLACEHOLDER": "Select SSW Category to filter",
        "LANGUAGE_FILTER_PLACEHOLDER": "Select Language to filter"
      },
      "ERROR": {
        "EMAIL_REQUIRED": "Email is required",
        "EMAIL_VALID": "Email must be a valid email address",
        "PASS_REQUIRED": "Password is required",
        "USER_TYPE_REQUIRED": "User Type is required",
        "FULL_NAME_REQUIRED": "Full Name is required"
      },
      "DASHBOARD": {
        "WELCOME": "Welcome to Global Works Dashboard"
      },
      "CANDIDATES": {
        "TITLE": "Candidate Lists",
        "SUBTITLE": "Please select filter parameters below to search for candidates.",
        "STUDENT_TITLE": "Student Lists",
        "STUDENT_SUBTITLE": "Select filter parameters below to search for students.",
        "FILTER_COUNTRY": "Filter Country",
        "FILTER_CATEGORY": "Filter Category",
        "FILTER_LANGUAGE": "Filter Language",
        "FILTER_ACADEMICS": "Filter Academics",
        "NO_CANDIDATES_FOUND": "No candidates were found with the above search filter.",
        "SELECT_AT_LEAST_ONE_COUNTRY": "Please select atleast one country to search candidates.",
        "PLEASE_SELECT_CATEGORY": "Please select a category you would like to search for.",
        "CANDIDATES_NOT_AVAILABLE": "Candidates not found."
      },
      "CANDIDATE_DETAILS": {
        "INVITE_FOR_INTERVIEW": "Invite For Interview",
        "SELECT_FOR_INTERVIEW": "Select For Interview",
        "ADDED_FOR_INTERVIEW": "Added for Interview",
        "REMOVE_FROM_INTERVIEW_LIST": "Remove from interview List",
        "ADD_TO_FAVOURITES": "Add to Favourites"
      },
      "CANDIDATE_PROFILE": {
        "PERSONAL_INFORMATIONS": "Personal Informations",
        "WORKER_QUALIFICATIONS_INFO": "Worker Qualification",
        "ACADEMIC_QUALIFICATION_INFO": "Academic Qualification",
        "PERSONAL_INFORMATION_1": "Personal Information 1",
        "PERSONAL_INFORMATION_2": "Personal Information 2",
        "HAVE_WORKED_JAPAN": "Have you worked in Japan before?",
        "CANDIDATE_PROFILE_UPDATE_FORM": "Candidate Profile Update Form",
        "CANDIDATE_ONBOARDING_FORM": "Candidate Onboarding Form",
        "CHECK_IF_WORKED_JAPAN": "Have you lived and worked in Japan before?",
        "YEAR": "Year",
        "MONTH": "Month",
        "DETAIL": "Detail",
        "DETAIL_PLACEHOLDER": "Detail about your prior stay",
        "COMPLETION": "Completion",
        "SSW_CATEGORY_NOTE": "The selected categories can not be changed once the profile is submitted."
      },
      "STUDENT_PROFILE": {
        "STUDENT_PROFILE_UPDATE_FORM": "Student Profile Update Form",
        "STUDENT_ONBOARDING_FORM": "Student Onboarding Form",
        "SPONSOR_INFORMATIONS": "Sponsors Informations",
        "SPONSOR_FINANCIAL_STATUS": "Sponsor's Financial Status",
        "ACADEMIC_QUALIFICATION_INFO": "Academic Qualification",
        "LANGUAGE_CERTIFICATION_INFORMATION": "Language Certication Informations",
        "FAMILY_DETAILS": "Family Details",
        "FAMILY_DETAILS_INFO": "Add your family member details"
      },
      "AVAILABLE_DEMAND": {
        "DEMAND_AVAILABLE": "Demand available",
        "PER_YEAR": "per Year",
        "APPLIED_SUCCESSFULLY": "Applied Successfully"
      },
      "DEMAND_LIST": {
        "ADD_NEW_DEMAND": "Add New Demand",
        "EDIT_DEMAND": "Edit Demand",
        "VIEW_APPLICANT_LIST": "View Applicant List",
        "NO_DEMAND_MESSAGE": "You do not have any demand yet. Add new demand using button above",
        "UPDATE_DEMAND": "Update Demand",
        "DEMAND_GENDER_NOTE": "Gender that can apply for this demand",
        "EXPECTED_SALARY_NOTE": "Please type expected salary/allowance in Japanese Yen",
        "DEADLINE_NOTE": "If deadline is added, candidates will not be allowed to apply for this demand post the deadline date"
      },
      "INTERVIEW_LIST": {
        "TITLE": "Interview Lists",
        "SELECT_USER_TYPE": "Select User Type",
        "SCHEDULE_INTERVIEW_DATE": "Schedule Interview Date",
        "CONTACT_CANDIDATE": "Contact Candidate",
        "REMOVE_FROM_LIST": "Remove from List"
      },
      "USERS": {
        "INVITE_USERS": "Invite User"
      },
      "INBOX": {
        "NO_MESSAGE_AVAILABLE": "No Message Available",
        "INBOX_EMPTY": "Inbox is Empty",
        "NO_MESSAGE": "No Message",
        "MESSAGE": "Message",
        "ALL_MESSAGES": "All Messages",
        "INTERVIEW_CANDIDATES": "Interview Candidates",
        "NO_CANDIDATES_AVAILABLE": "No Candidates Available For This Demand",
        "CLOSE_CONVERSATION": "Close Conversation",
        "ADD_ATTACHMENTS": "Add Attachments",
        "CLOSE_CONVERSATION_MESSAGE": "This conversation has been closed. You can no longer send the message.",
        "SEND": "Send",
        "START_CONVERSATION": "Click Here To Start Conversation"
      },
      "SETTINGS": {
        "PASSWORD": "Password",
        "CURRENT_PASSWORD": "Current Password",
        "NEW_PASSWORD": "New Password",
        "CONFIRM_PASSWORD": "Confirm Password",
        "USER_MANAGEMENT": "User Management",
        "SUB_USERS_TABLE": "Sub Users Table"
      },
      "UTILITY": {
        "CATEGORY_LIST": {
          "NURSING_CARE": "Nursing Care",
          "BUILDING": "Building Cleaning Management",
          "MACHINE": "Machine Parts and Tooling Industries",
          "INDUSTRIAL": "Industrial Machinery Industry",
          "ELECTRIC": "Electric, Electronics, and Information Industries",
          "CONSTRUCTION": "Construction Industry",
          "SHIP": "Ship building and Ship Machinery Industry",
          "AUTOMOBILE": "Automobile Repair and Maintenance",
          "AVIATION": "Aviation Industry",
          "ACCOMMODATION": "Accommodation Industry",
          "AGRICULTURE": "Agriculture",
          "FISHERY": "Fishery and Aquaculture Industries",
          "MANUFACTURE": "Manufacture of Food and Beverages",
          "FOOD_SERVICE": "Food Service Industry"
        },
        "COUNTRIES": {
          "JAPAN": "Japan",
          "NEPAL": "Nepal",
          "BANGLADESH": "Bangladesh",
          "VIETNAM": "Vietnam"
        }
      },
      "SCHOOL_LIST": {
        "TITLE": "School List",
        "ALL_SCHOOLS": "All Schools",
        "UNIVERSITY": "University",
        "VOCATIONAL": "Vocational School",
        "ALL_ADMISSION": "All Admission Type",
        "OPEN_ADMISSION": "Admission Open Only"
      }
    }
  }
}
