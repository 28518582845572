import { MenuItem } from './menu.model'

export const ADMINMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'MENUITEMS.CANDIDATES',
    icon: 'bx bxs-group',
    link: '/candidates',
  },
  { id: 3, label: 'MENUITEMS.COMPANIES', icon: 'bx bxs-business', link: '/companies' },
  { id: 3, label: 'MENUITEMS.JAPANESE_SCHOOLS', icon: 'bx bxs-business', link: '/jp-schools' },
  { id: 6, label: 'MENUITEMS.USERS', icon: 'bx-user', link: '/users' },
]

export const COMPANYMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'MENUITEMS.CANDIDATES',
    icon: 'bx bxs-group ',
    link: '/candidates',
  },
  {
    id: 3,
    label: 'MENUITEMS.DEMAND_LIST',
    icon: 'bx-list-ul',
    link: '/demand-list',
  },
  {
    id: 4,
    label: 'MENUITEMS.INBOX',
    icon: 'bx-envelope',
    link: '/inbox',
  },
]

export const LANGUAGESCHOOLMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'MENUITEMS.CANDIDATES',
    icon: 'bx bxs-group',
    link: '/school-candidates',
  }, {
    id: 2,
    label: 'MENUITEMS.DEMAND_AVAILABLE',
    icon: 'bx-briefcase-alt-2',
    link: '/available-demand',
  },
  // {
  //   id: 2,
  //   label: 'MENUITEMS.SCHOOL_LIST',
  //   icon: 'bx bxs-school',
  //   link: '/school-list',
  // },
   {
    id: 3,
    label: 'MENUITEMS.INBOX',
    icon: 'bx-envelope',
    link: '/inbox',
  },
]

export const JAPANESESCHOOLMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'MENUITEMS.STUDENTS',
    icon: 'bx bxs-group',
    link: '/students',
  },
  {
    id: 3,
    label: 'MENUITEMS.INBOX',
    icon: 'bx-envelope',
    link: '/inbox',
  },
  {
    id: 4,
    label: 'MENUITEMS.INTERVIEW_LIST',
    icon: 'bxs-user',
    link: '/student-interview-list',
  },
]
export const CANDIDATEMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'MENUITEMS.DEMAND_AVAILABLE',
    icon: 'bx-briefcase-alt-2',
    link: '/available-demand',
  },
  {
    id: 3,
    label: 'MENUITEMS.INBOX',
    icon: 'bx-envelope',
    link: '/inbox',
  },
]

export const STUDENTMENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS',
    icon: 'bx-home-circle',
    link: '/dashboard',
  }, {
    id: 2,
    label: 'MENUITEMS.SCHOOL_LIST',
    icon: 'bx bxs-school',
    link: '/school-list',
  },
  {
    id: 3,
    label: 'MENUITEMS.INBOX',
    icon: 'bx-envelope',
    link: '/inbox',
  },
]
