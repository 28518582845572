import { Injectable } from '@angular/core'

import { getFirebaseBackend } from '../../authUtils'
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore'
import { AuthUser, UserModel } from '../models/auth.models'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Subscription } from 'rxjs'
import { UserService } from './user.service'
import { Session } from 'protractor'
import { AngularFireAuth } from '@angular/fire/auth'
import firebase from 'firebase/app'
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  authUser: AuthUser
  subscribed: Subscription | undefined

  constructor(
    public AngularFirestore: AngularFirestore,
    private http: HttpClient,
    private UserService: UserService,
    public fireAuth: AngularFireAuth,
  ) {}

  /**
   * Returns the current user
   */
  public currentUser(): AuthUser {
    return getFirebaseBackend().getAuthenticatedUser()
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    localStorage.removeItem("user")
    sessionStorage.removeItem("authUser")
    return getFirebaseBackend()
      .loginUser(email, password)
      .then(async (response: any) => {
        if (response) {
          localStorage.setItem("justLoggedIn", "true")
          const uid = response.user.uid
          await this.UserService.loadUser(uid)
          return response
        }
      })
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  registerTempUser(
    email: string,
    password: string,
    name: string,
    type: string,
  ) {
    return this.http.post(
      'https://us-central1-global-worker-data.cloudfunctions.net/createAccount',
      { email, password, name, type },
    )
  }

  // registerTempUser(email: string, full_name: string, password: string, status: string) {
  //     const ref = this.AngularFirestore.collection('temp_user').ref.doc();
  //     ref.set({
  //         id: ref.id,
  //         full_name: full_name,
  //         email: email,
  //         created_at: new Date(),
  //         password: password,
  //         status: status
  //     });
  // }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response: any) => {
        const success = response
        return success
      })
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    localStorage.clear()
    sessionStorage.clear()
    getFirebaseBackend().logout()
  }

  /**authenticate user
   *  password
   * @param currentPassword current password
   */
  checkUsercredential(currentPassword: string) {
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword,
    )
    return user.reauthenticateWithCredential(credential)
  }

  /**
   * Update password
   * @param password new password
   */

  updatePassword(password: string) {
    const user = firebase.auth().currentUser
    return user.updatePassword(password)
  }

  registerNewUser(payload: any) {
    return this.http.post(
      `https://us-central1-global-worker-data.cloudfunctions.net/createAccount`,
      payload,
    )
  }
}
