<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <!-- <img src="assets/images/logo.svg" alt="" height="22"> -->
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="17"> -->
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <!-- <img src="assets/images/logo-light.svg" alt="" height="22"> -->
          </span>
          <span class="logo-lg">
            <h4 style="color: aliceblue">Global Works</h4>
            <!-- <img src="assets/images/logo-light.png" alt="" height="19"> -->
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{ flagvalue }}" alt="Header Language" height="16" />
          <span class="ms-1">{{ countryName }}</span>
          <img *ngIf="flagvalue === undefined" src="{{ valueset }}" alt="Header Language" height="16" />
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{ active: cookieValue === item.lang }">
            <img src="{{ item.flag }}" alt="user-image" class="ms-1 m-2" height="12" />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>
      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ 'HEADER.NOTIFICATIONS.TITLE' | translate }}
                </h6>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="text-center mt-4">No Notifications</div>
            </a>
          </ngx-simplebar>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item d-flex align-items-center" id="page-header-user-dropdown"
          ngbDropdownToggle>
          <div class="rounded-circle header-profile-user fs-5 align-items-center d-flex justify-content-center">
            {{ currentUser ? currentUser.name.charAt(0).toUpperCase() : '' }}
          </div>
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
          <span class="d-none d-xl-inline-block ms-1">{{
            currentUser ? currentUser.name : ''
            }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item pointer" (click)="profile()"><i
              class="bx bx-user font-size-16 align-middle me-2"></i>
            {{ 'HEADER.PROFILE' | translate }}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" routerLink="profile/setting"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-2"></i>{{
            'HEADER.SETTINGS' | translate }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-2 text-danger"></i>
            {{ 'HEADER.LOGOUT' | translate }}</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{ item.icon }} me-2"></i>{{ item.label | translate }}
                <div class="arrow-down" *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{ item.icon }} me-1"></i>
                {{ item.label | translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{
                  'dropdown-mega-menu-xl px-2': item.subItems.length > 11
                }" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    {{ subitem.label | translate }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4">
                        <a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="subitem.link">{{ item.subItems[i].label | translate }}</a>
                      </div>
                      <div class="col-lg-4">
                        <a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link">{{ item.subItems[i + 1]?.label | translate }}</a>
                      </div>
                      <div class="col-lg-4">
                        <a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link">{{ item.subItems[i + 2]?.label | translate }}</a>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link" routerLinkActive="active">{{
                          subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">
                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link" routerLinkActive="active">{{ subSubSubitem.label |
                                translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>