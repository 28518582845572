import { Component, OnInit, AfterViewInit } from '@angular/core'

import { EventService } from '../core/services/event.service'

import {
  LAYOUT_VERTICAL,
  LAYOUT_HORIZONTAL,
  LAYOUT_WIDTH,
  TOPBAR,
} from './layouts.model'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  layoutwidth: string
  topbar: string

  constructor(private eventService: EventService) {}

  ngOnInit() {
    // default settings
    this.layoutwidth = LAYOUT_WIDTH
    this.topbar = TOPBAR

    document.body.setAttribute('data-layout-size', 'fluid')
    document.body.classList.remove('vertical-collpsed')
    document.body.removeAttribute('data-layout-scrollable')
  }

  ngAfterViewInit() {}
}
