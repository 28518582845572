import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { SimplebarAngularModule } from 'simplebar-angular'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { ClickOutsideModule } from 'ng-click-outside'

import { UIModule } from '../shared/ui/ui.module'
import { LayoutComponent } from './layout.component'
import { FooterComponent } from './footer/footer.component'
import { HorizontalComponent } from './horizontal/horizontal.component'
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component'
import { LanguageService } from '../core/services/language.service'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    FooterComponent,
    HorizontalComponent,
    HorizontaltopbarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    SimplebarAngularModule,
  ],
  providers: [LanguageService],
})
export class LayoutsModule {}
