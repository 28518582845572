import { Component, OnInit, AfterViewInit, Inject } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { LanguageService } from '../../core/services/language.service'

import { EventService } from '../../core/services/event.service'
import { AuthenticationService } from '../../core/services/auth.service'

import { DOCUMENT } from '@angular/common'

import { ADMINMENU, CANDIDATEMENU, COMPANYMENU, LANGUAGESCHOOLMENU, JAPANESESCHOOLMENU, STUDENTMENU } from './menu'
import { MenuItem } from './menu.model'
import { environment } from '../../../environments/environment'
import { UserService } from 'src/app/core/services/user.service'
import { UserModel } from 'src/app/core/models/auth.models'

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss'],
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  element
  cookieValue
  flagvalue
  countryName
  valueset

  menuItems = []

  disabledMenuItems = []

  currentUser: UserModel
  currentUserType: string

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: '日本語', flag: 'assets/images/flags/japan.png', lang: 'jp' }
  ]

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private eventService: EventService,
    private authService: AuthenticationService,
    private userService: UserService,
    public languageService: LanguageService,
    // tslint:disable-next-line: variable-name
    public _cookiesService: CookieService,
  ) {
    this.userService.user.subscribe((data: UserModel | null | undefined) => {
      if (data != null || data != undefined) {
        this.currentUser = data
        // Refresh Top nav bar if user changes
        if (data.type != this.currentUserType) {
          this.currentUserType = this.currentUser.type
        }

        // disable nav menu conditionally
        if (data.type == 'candidate' && !data.candidate_id) {
          this.disabledMenuItems = ["/available-demand"]
        } else {
          this.disabledMenuItems = []
        }
        this.initialize()
      }
    })

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu()
      }
    })
  }

  ngOnInit(): void {
    this.element = document.documentElement

    this.initialize()

    this.cookieValue = this._cookiesService.get('lang')
    const val = this.listLang.filter((x) => x.lang === this.cookieValue)
    this.countryName = val.map((element) => element.text)
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg'
      }
    } else {
      this.flagvalue = val.map((element) => element.flag)
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text
    this.flagvalue = flag
    this.cookieValue = lang
    this.languageService.setLanguage(lang)
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout()
    this.router.navigate(['/auth/login'])
  }
  /**
   * redirect to respective profile
   */
  profile() {
    if (['company', 'candidate', 'jp_school', 'language_school' ].includes(this.currentUser?.type)) {
      this.router.navigate(['/profile'])
    }
  }

  /**
   * On menu click
   */
  onMenuClick(event) {
    const nextEl = event.target.nextElementSibling
    if (nextEl) {
      const parentEl = event.target.parentNode
      if (parentEl) {
        parentEl.classList.remove('show')
      }
      nextEl.classList.toggle('show')
    }
    return false
  }

  ngAfterViewInit() {
    this.activateMenu()
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className)
    while (els[0]) {
      els[0].classList.remove(className)
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content')
    element.classList.toggle('show')
  }

  /**
   * Activates the menu
   */
  private activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement
      if (parent) {
        parent.classList.remove('active')
        const parent2 = parent.parentElement
        this._removeAllClass('mm-active')
        this._removeAllClass('mm-show')
        if (parent2) {
          parent2.classList.remove('active')
          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove('active')
            const parent4 = parent3.parentElement
            if (parent4) {
              parent4.classList.remove('active')
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove('active')
                const menuelement = document.getElementById(
                  'topnav-menu-content',
                )
                if (menuelement !== null) {
                  if (menuelement.classList.contains('show'))
                    document
                      .getElementById('topnav-menu-content')
                      .classList.remove('show')
                }
              }
            }
          }
        }
      }
    }

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i])
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('active')
          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('active')
            const parent4 = parent3.parentElement
            if (parent4) {
              parent4.classList.add('active')
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add('active')
                const parent6 = parent5.parentElement
                if (parent6) {
                  parent6.classList.add('active')
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    switch (this.currentUserType) {
      case 'admin':
        this.menuItems = ADMINMENU
        break
      case 'system':
        this.menuItems = ADMINMENU
        break
      case 'company':
        this.menuItems = COMPANYMENU
        break
      // case 'student':
      //   this.menuItems = STUDENTMENU
      //   break
      case 'language_school':
        this.menuItems = LANGUAGESCHOOLMENU
        break
      case 'jp_school':
        this.menuItems = JAPANESESCHOOLMENU
        break
      case 'candidate':
        if (this.currentUser?.application_type == 'worker') {
          this.menuItems = CANDIDATEMENU
          if (this.disabledMenuItems.length) {
            this.menuItems = this.menuItems.filter((each: any) => !this.disabledMenuItems.find((item: string) => each.link == item))
          }
        } else if (this.currentUser?.application_type == 'student') {
          this.menuItems = STUDENTMENU
        }
        break
      default:
        this.menuItems = []
        break
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false
  }
}
