import { Injectable } from '@angular/core'

import { UserModel } from '../models/auth.models'
import { BehaviorSubject, Subscription } from 'rxjs'
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore'
import { AngularFireAuth } from '@angular/fire/auth'
import * as moment from 'moment'
import { Candidate } from '../models/candidate.model'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class UserService {
  private localuser: UserModel | null = null
  subscribed: Subscription | undefined
  profileSubscribed: Subscription | undefined
  user = new BehaviorSubject<UserModel | null>(null)
  // candidateProfile = new BehaviorSubject<Candidate | null>(null)

  constructor(
    private AngularFirestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router
  ) {
    var userObj = JSON.parse(localStorage.getItem('user')!)
    if (userObj != null) {
      this.localuser = userObj
      this.user.next(userObj)
    }
    this.auth.user.subscribe((user) => {
      if (user == null) {
        if (this.subscribed) this.subscribed.unsubscribe()
        return
      } else {
        this.loadUser(user.uid)
      }
    })
  }

  loadUser(uid: string) {
    this.subscribed = this.AngularFirestore.collection<UserModel>('users')
      .doc(uid)
      .valueChanges()
      .subscribe(async (data) => {
        if (data) {
          localStorage.setItem('user', JSON.stringify(data))
          this.user.next(data)
          let justLoggedIn = localStorage.getItem("justLoggedIn")
          if (justLoggedIn && ['company', 'candidate', 'jp_school', 'language_school'].includes(data?.type) && !(data?.candidate_id || data?.company_id || data?.school_id)) {
            this.router.navigate(['/profile'])
          }
          localStorage.removeItem("justLoggedIn")
          // if (data.type == 'candidate' && data.candidate_id) await this.loadCandidateProfile(data.candidate_id);
        }
      })
  }

  // loadCandidateProfile(id: string) {
  //   return new Promise((resolve, reject) => {
  //     return this.profileSubscribed = this.AngularFirestore.collection<Candidate>('candidates')
  //       .doc(id)
  //       .valueChanges()
  //       .subscribe((data) => {
  //         if (data) {
  //           localStorage.setItem('profile', JSON.stringify(data))
  //           this.candidateProfile.next(data)
  //         }
  //       })
  //   });
  // }

  getUsers() {
    return this.AngularFirestore.collection<UserModel>(`users`, (ref) =>
      ref.where('type', '!=', 'admin').orderBy('type', 'asc').orderBy('created_at', 'desc').limit(10),
    ).get()
  }

  getUsersByType(type: string) {
    return this.AngularFirestore.collection<UserModel>(`users`, (ref) =>
      ref.where('type', '==', type).orderBy('name', 'desc'),
    ).get()
  }

  updateUser(userData: UserModel) {
    this.AngularFirestore.collection<UserModel>('users')
      .doc(userData.id)
      .update(userData)
    localStorage.setItem('user', JSON.stringify(userData))
  }

  addNewUser(user: any, uid: any) {
    const userRef: AngularFirestoreDocument<any> = this.AngularFirestore.doc(
      `users/${uid}`,
    )

    const userData = {
      uid: uid,
      email: user.email,
      name: user.name,
      type: user.type,
      status: user.status,
      created_at: new Date(),
      updated_at: new Date(),
    }
    return userRef.set(userData, {
      merge: true,
    })
  }

  addTempUser(data: any) {
    data.created_at = new Date()
    data.updated_at = new Date()
    const ref = this.AngularFirestore.collection<UserModel>(`temp_user`).doc()
    data.id = ref.ref.id
    return ref.set(data)
  }

  getUserForSignup(id: string) {
    return this.AngularFirestore.collection<UserModel>(`temp_user`)
      .doc(id)
      .get()
  }

  updateTempUser(id: string) {
    return this.AngularFirestore.collection<UserModel>('temp_user')
      .doc(id)
      .update({
        status: 'complete',
      })
  }

  getInvitedUser(company: string) {
    return this.AngularFirestore.collection<UserModel>(`temp_user`, (ref) =>
      ref.where('company', '==', company),
    ).get()
  }

  checkUserEmail(email: string) {
    return this.AngularFirestore.collection<UserModel>(`users`, (ref) =>
      ref.where('email', '==', email),
    ).get()
  }

  checkTempUserEmail(email: string) {
    return this.AngularFirestore.collection<UserModel>(`temp_user`, (ref) =>
      ref.where('email', '==', email),
    ).get()
  }

  getTempUsersByType(type: string) {
    return this.AngularFirestore.collection<UserModel>(`temp_user`, (ref) =>
      ref.where('type', '==', type).where('status', '!=', 'complete'),
    ).get()
  }

  getTempUsers() {
    return this.AngularFirestore.collection<UserModel>(`temp_user`, ref =>
      ref.where('status', '!=', 'complete').orderBy('status', 'asc').orderBy('created_at', 'desc').limit(10),
    ).get()
  }

}
