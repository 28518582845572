// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDoT76KSmdUYXgvYqlLqPShDLU92O9vzg4',
    authDomain: 'global-worker-data.firebaseapp.com',
    projectId: 'global-worker-data',
    storageBucket: 'global-worker-data.appspot.com',
    messagingSenderId: '796391947672',
    appId: '1:796391947672:web:46c418a72e1822793d7fed',
    measurementId: 'G-1S9QPZ2YND',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
