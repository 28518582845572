import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap'

import { PagetitleComponent } from './pagetitle/pagetitle.component'
import { LoaderComponent } from './loader/loader.component'
import { LoadingComponent } from './loading/loading.component'

@NgModule({
  declarations: [PagetitleComponent, LoaderComponent, LoadingComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
  ],
  exports: [PagetitleComponent, LoaderComponent, LoadingComponent],
})
export class UIModule {}
